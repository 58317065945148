.portfolio__container {
    grid-template-columns: repeat(4, 270px);
    justify-content: center;
    column-gap: 2rem;
}

.portfolio__content {
    background-color: var(--primary-color);
    color: var(--heading-color);
    padding: 2rem;
    border-radius: 1.25rem;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.portfolio__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    color: white;
    text-align: left
}

.portfolio__description {
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-2);
    flex-grow: 1;
    text-align: left;
}

.portfolio__button {
    background-color: var(--secondary-color);
    color: var(--heading-color);
    font-weight: var(--font-medium);
    padding: 0.5rem 1rem;
    border-radius: 30px;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    margin-top: auto;
}

.portfolio__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.portfolio__button:hover .portfolio__button-icon {
    transform: translateX(0.25rem);
}

/* Breakpoints */
@media screen and (max-width: 768px) {
    .portfolio__container {
        grid-template-columns: repeat(1, 1fr);
    }
}

.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
    padding: 1rem;
}

.nav {
    height: calc(var(--header-height)) + 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav__logo,
.nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__list {
    display: flex;
    column-gap: 2rem;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: .3s;
}

.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}
/* Adjust nav__close inside grid menu */
.nav__close-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Set the nav__close styling */
.nav__close {
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
}

/* Active link */
.active-link,
.nav__link:hover {
    color: var(--title-color-dark);
}

/* Change background header */
.scroll-header {
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/* ======== BREAKPOINTS ===== */
/* For medium devices */
@media screen and (max-width: 768px){
    .header {
        top: initial;
        bottom: 0;
    }

    .nav {
        height: var(--header-height);
    }

    .nav__menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }

    /* Show Menu */
    .show-menu {
        bottom: 0;
    }

    .nav__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    .nav__close-item {
        grid-column: span 3;
        justify-self: center;
    }

    .nav__icon {
        font-size: 1.2rem;
    }

    .nav__close:hover {
        color: var(--title-color-dark);
    }

    .nav__toggle {
        font-size: 1.1rem;
        cursor: pointer;
    }

    .nav__icon,
    .nav__close,
    .nav__toggle {
        display: block;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .nav__menu {
        padding: 2rem 0.25rem 4rem;
    }

    .nav__list {
        column-gap: 0;
    }
}
.about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;
}

.about__img {
    width: 350px;
    height: 550px;
    object-fit: cover;
    border-radius: 1.5rem;
    justify-self: center;
}

.about__info {
    grid-template-columns: repeat(3, 140px);
    gap: 1rem;
    margin-bottom: var(--mb-2);
}

.about__box {
    background-color: var(--primary-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1.25rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about__icon {
    font-size: 1.5rem;
    color: var(--heading-color);
    margin-bottom: var(--mb-0-5);
}

.about__title {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    color: var(--heading-color);
}

.about__subtitle {
    font-size: var(--tiny-font-size);
    color: var(--heading-color);
}

.about__description {
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal__content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.modal__content h3 {
    margin-bottom: 1rem;
}

.modal__content p {
    margin-bottom: 2rem;
}

.modal .button {
    background-color: var(--button-color);
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.modal .button:hover {
    background-color: #45a049;
}


/* ====== BREAKPOINTS ======== */
/* For large devices */
@media screen and (max-width: 992px) {
    .about__container {
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }

    .about__img {
        display: none;
    }

    .about__box {
        padding: 0.75rem 0.5rem;
    }

    .about__data {
        text-align: center;
    }

    .about__info {
        justify-content: center;
    }

    .about__description {
        padding: 0 5rem;
        margin-bottom: 2rem;
    }
}

/* For medium devices */
@media screen and (max-width: 567px) {
    .about__info {
        grid-template-columns: repeat(3, 1fr);
    }

    .about__description {
        padding: 0;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .about__info {
        grid-template-columns: repeat(2, 1fr);
    }
}
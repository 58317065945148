.project-template {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}


.project-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 30px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.project-heading {
    font-size:2rem;
    font-weight: 550;
    margin-bottom: 30px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
}

.project-sub-heading {
    font-size:1.2rem;
    font-weight: 550;
    margin-bottom: 30px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
}

.project-text {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 30px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
}

.large-project-image {
    width: 90%;
    height: auto;
    margin: 0 auto 30px auto;
    display: block;
}

.project-image {
    width: 50%;
    height: auto;
    margin: 0 auto 30px auto;
    display: block;
}

.project-list {
    list-style-type: disc;
    padding-left: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
    margin-bottom: 30px;
}

.project-list-item {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 10px;
}

.language-switch {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.language-switch:hover {
    background-color: #0056b3;
}

.language-switch:focus {
    outline: none;
}

/* Styling for code blocks */
.project-code {
    background-color: #f4f4f4;
    color: black;
    border-left: 5px solid #ddd;
    padding: 10px;
    margin: 20px 0;
    white-space: pre;
    margin-bottom: 30px;
    font-family: 'Courier New', Courier, monospace;
    overflow-x: auto;
    max-width: 100%;
    word-wrap: normal;
    border-radius: 5px;
}

.project-code-header {
    font-size: 1.2em;
    margin-bottom: 10px;
    font-weight: bold;
}

.custom-link {
    color: black;
    font-weight: bold;
    text-decoration: underline;
}


/* Responsive Design */
@media (max-width: 768px) {
    .project-title {
        font-size: 1.8rem;
    }

    .project-text {
        font-size: 1rem;
    }

    .project-list-item,
    .project-list {
        font-size: 1rem;
    }

    .project-image {
        width: 80%;
    }

    .large-project-image {
        width: 100%;
    }
}
